import { useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { Page, Document } from "react-pdf";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LoaderDrop from "./LoaderDrop";

function PdfPreivew({ pdfFile, openPdf, setOpenPdf, loading }) {
  const [numPages, setNumPages] = useState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onDocumentLoadSuccess = (value) => {
    console.log("llllllllliiiiiiiiiiiiiiii", value);
    setNumPages(value._pdfInfo.numPages);
  };
  const handleClosePdf = () => {
    setOpenPdf(false);
  };

  console.log("llllllllliiiiiiiiiiiiiiii", pdfFile);
  console.log("llllllllliiiiiiiiiiiiiiii", numPages);

  return (
    <Dialog onClose={handleClosePdf} open={openPdf}>
      <LoaderDrop start={loading} />
      <DialogTitle id="alert-dialog-title">PDF PREVIEW</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClosePdf}
        sx={{
          position: "absolute",
          right: 15,
          top: 8,
          color: (value) => value.palette.grey[500],
        }}
        size="large"
      >
        <Icon icon="material-symbols:close-rounded" color="black" />
      </IconButton>
      <DialogContent>
        {/* <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
          {numPages &&
            [...Array(numPages)].map((_, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                width={isMobile ? 280 : 400}
              />
            ))}
        </Document> */}
        <div
          style={{ width: "100%", height: "500px", border: "1px solid #ddd" }}
        >
          <iframe src={pdfFile} width="100%" height="100%" title=" " />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClosePdf} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
PdfPreivew.propTypes = {
  pdfFile: PropTypes.any,
  openPdf: PropTypes.bool,
  setOpenPdf: PropTypes.func,
  loading: PropTypes.bool,
};

export default PdfPreivew;
