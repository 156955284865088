// import dashboard from "./dashboard";
// import sales from "./sales";
// import purchase from "./purchase";
// import reports from "./reports";
// import accounts from "./accounts";
// import others from "./others";
// import settings from "./settings";

// ==============================|| MENU ITEMS ||============================== //

// const array = [
//   "dashboard",
//   "sales",
//   "purchase",
//   "reports",
//   "accounts",
//   "others",
//   // "settings",
// ];
// const menuItems = {
//   items: [],
// };

// const importMap = {
//   dashboard,
//   sales,
//   purchase,
//   reports,
//   accounts,
//   others,
//   settings,
// };

// array.forEach((item) => {
//   if (importMap[item]) {
//     menuItems.items.push(importMap[item]);
//   }
// });
import {
  IconReportMoney,
  IconTruckReturn,
  IconShoppingCartPlus,
  IconShoppingCart,
  IconDashboard,
  IconCrown,
  IconCashBanknote,
  IconCoin,
  IconReportAnalytics,
  IconStorm,
  IconFileAnalytics,
  IconDotsCircleHorizontal,
  IconCategory,
  IconShieldLock,
  IconVectorBezierCircle,
  IconStack3,
} from "@tabler/icons";
// constant
const icons = {
  IconTruckReturn,
  IconShoppingCart,
  IconShoppingCartPlus,
  IconDashboard,
  IconCrown,
  IconCashBanknote,
  IconCoin,
  IconReportAnalytics,
  IconReportMoney,
  IconStorm,
  IconFileAnalytics,
  IconDotsCircleHorizontal,
  IconCategory,
  IconShieldLock,
  IconVectorBezierCircle,
  IconStack3,
};

// const menuItems = [
//   {
//     id: "dashboard",
//     title: "Dashboard",
//     type: "group",
//     url: "/dashboard/default",
//     icon: icons.IconDashboard,
//     breadcrumbs: false,
//   },
//   {
//     id: "product",
//     title: "Product",
//     type: "group",
//     url: "/product",
//     icon: icons.IconDashboard,
//     breadcrumbs: false,
//   },
//   {
//     id: "master",
//     title: "Master",
//     type: "group",
//     url: "/master",
//     icon: icons.IconDashboard,
//     breadcrumbs: false,
//   },
//   {
//     id: "accounts",
//     title: "Accounts",
//     type: "group",
//     children: [
//       {
//         id: "receipt",
//         title: " Receipt",
//         type: "item",
//         url: "/accounts/receipt",
//         icon: icons.IconReportMoney,
//         breadcrumbs: false,
//       },
//       {
//         id: "payment",
//         title: "Payment",
//         type: "item",
//         url: "/accounts/payment",
//         icon: icons.IconFileAnalytics,
//         breadcrumbs: false,
//       },
//     ],
//   },
//   {
//     id: "sales",
//     title: "Sales",
//     type: "group",
//     children: [
//       {
//         id: "sales-pos",
//         title: "Sales B2B",
//         type: "item",
//         url: "/sales/salesPos",
//         icon: icons.IconReportMoney,
//         breadcrumbs: false,
//       },
//       {
//         id: "sales-posb2c",
//         title: "Sales B2C",
//         type: "item",
//         url: "/sales/salesPosb2c",
//         icon: icons.IconReportMoney,
//         breadcrumbs: false,
//       },
//       {
//         id: "salesReturn",
//         title: "Sales Return",
//         type: "item",
//         url: "/sales/salesReturn",
//         icon: icons.IconTruckReturn,
//         breadcrumbs: false,
//       },
//       {
//         id: "salesReturnB2C",
//         title: "Sales ReturnB2C",
//         type: "item",
//         url: "/sales/salesReturnB2C",
//         icon: icons.IconTruckReturn,
//         breadcrumbs: false,
//       },
//       {
//         id: "orderForm",
//         title: "Order form ",
//         type: "item",
//         url: "/sales/orderForm",
//         icon: icons.IconTruckReturn,
//         breadcrumbs: false,
//       },
//     ],
//   },
//   {
//     id: "purchases",
//     title: "Purchases",
//     type: "group",
//     children: [
//       {
//         id: "purchase",
//         title: "Purchase",
//         type: "item",
//         url: "/purchase/purchasebill",
//         icon: icons.IconShoppingCartPlus,
//         breadcrumbs: false,
//       },
//       {
//         id: "purchaseRetrun",
//         title: "Purchase Return",
//         type: "item",
//         url: "/purchase/purchasebillReturn",
//         icon: icons.IconTruckReturn,
//         breadcrumbs: false,
//       },
//       {
//         id: "purchaseImport",
//         title: "Purchase Import",
//         type: "item",
//         url: "/purchase/purchaseImport",
//         icon: icons.IconTruckReturn,
//         breadcrumbs: false,
//       },
//       {
//         id: "purchaseOrder",
//         title: "Purchase Order",
//         type: "item",
//         url: "/purchase/PurchaseOrder",
//         icon: icons.IconTruckReturn,
//         breadcrumbs: false,
//       },
//     ],
//   },
//   {
//     id: "reports",
//     title: "Reports",
//     type: "group",
//     children: [
//       // {
//       //     id: 'GST-Reports',
//       //     title: 'GST Reports',
//       //     type: 'item',
//       //     url: '/reports/gst',
//       //     icon: icons.IconCoin,
//       //     breadcrumbs: false
//       // },
//       {
//         id: "VAT-Reports",
//         title: "VAT Reports",
//         type: "item",
//         url: "/reports/vat",
//         icon: icons.IconCashBanknote,
//         breadcrumbs: false,
//       },
//       {
//         id: "Sales-Report",
//         title: "Sales Report",
//         type: "item",
//         url: "/reports/SalesReport",
//         icon: icons.IconReportMoney,
//         breadcrumbs: false,
//       },
//       {
//         id: "SalesReturn-Report",
//         title: "SalesReturn Report",
//         type: "item",
//         url: "/reports/SalesReturnReport",
//         icon: icons.IconFileAnalytics,
//         breadcrumbs: false,
//       },
//       {
//         id: "Purchase-Report",
//         title: "Purchase Report",
//         type: "item",
//         url: "/reports/PurchaseReport",
//         icon: icons.IconFileAnalytics,
//         breadcrumbs: false,
//       },
//       {
//         id: "Stock-Report",
//         title: "Stock Report",
//         type: "item",
//         url: "/reports/Stock",
//         icon: icons.IconTruckReturn,
//         breadcrumbs: false,
//       },
//       {
//         id: "Batch-WiseStock-Report",
//         title: "Batch Wise Stock Report",
//         type: "item",
//         url: "/reports/BatchWiseStock",
//         icon: icons.IconTruckReturn,
//         breadcrumbs: false,
//       },
//       {
//         id: "Bill-Wise-Profit-Report",
//         title: "Bill Wise Profit Report",
//         type: "item",
//         url: "/reports/BillWiseProfitReport",
//         icon: icons.IconTruckReturn,
//         breadcrumbs: false,
//       },
//     ],
//   },
//   {
//     id: "setup",
//     title: "Setup",
//     type: "group",
//     children: [
//       {
//         id: "category/subcategory",
//         title: "Category/Sub",
//         type: "item",
//         url: "/others/createcategory",
//         icon: icons.IconBrandAsana,
//         breadcrumbs: false,
//       },
//       {
//         id: "userPrivilege",
//         title: "User Privilege",
//         type: "item",
//         url: "/others/userPrivilege",
//         icon: icons.IconBrandAsana,
//         breadcrumbs: false,
//       },
//       {
//         id: "taxUtilities",
//         title: "Tax Utilities",
//         type: "item",
//         url: "/others/taxUtilities",
//         icon: icons.IconBrandAsana,
//         breadcrumbs: false,
//       },
//       {
//         id: "CurrencyRate",
//         title: "Currency Rate",
//         type: "item",
//         url: "/others/CurrencyRate",
//         icon: icons.IconBrandAsana,
//         breadcrumbs: false,
//       },
//     ],
//   },
// ];

const menuItems = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "group",
    url: "/dashboard/default",
    icon: icons.IconDashboard,
    breadcrumbs: false,
    condition: [
      {
        conditionTitle: "view",
        status: true,
      },
    ],
  },
  {
    id: "product",
    title: "Product",
    type: "group",
    url: "/product",
    icon: icons.IconDashboard,
    breadcrumbs: false,
    condition: [
      {
        conditionTitle: "view",
        status: true,
      },
      {
        conditionTitle: "create",
        status: true,
      },
      {
        conditionTitle: "update",
        status: true,
      },
      {
        conditionTitle: "delete",
        status: true,
      },
      {
        conditionTitle: "print",
        status: true,
      },
    ],
  },
  {
    id: "master",
    title: "Master",
    type: "group",
    url: "/master",
    icon: icons.IconDashboard,
    breadcrumbs: false,
    condition: [
      {
        conditionTitle: "view",
        status: true,
      },
      {
        conditionTitle: "create",
        status: true,
      },
      {
        conditionTitle: "update",
        status: true,
      },
      {
        conditionTitle: "delete",
        status: true,
      },
      {
        conditionTitle: "print",
        status: true,
      },
    ],
  },
  {
    id: "accounts",
    title: "Accounts",
    type: "group",
    condition: [
      {
        conditionTitle: "view",
        status: true,
      },
      {
        conditionTitle: "create",
        status: true,
      },
      {
        conditionTitle: "update",
        status: true,
      },
      {
        conditionTitle: "delete",
        status: true,
      },
      {
        conditionTitle: "print",
        status: true,
      },
    ],
    children: [
      {
        id: "receipt",
        title: " Receipt",
        type: "item",
        url: "/accounts/receipt",
        icon: icons.IconReportMoney,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
      {
        id: "payment",
        title: "Payment",
        type: "item",
        url: "/accounts/payment",
        icon: icons.IconFileAnalytics,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
    ],
  },
  {
    id: "sales",
    title: "Sales",
    type: "group",
    condition: [
      {
        conditionTitle: "view",
        status: true,
      },
      {
        conditionTitle: "create",
        status: true,
      },
      {
        conditionTitle: "update",
        status: true,
      },
      {
        conditionTitle: "delete",
        status: true,
      },
      {
        conditionTitle: "print",
        status: true,
      },
    ],
    children: [
      {
        id: "sales-pos",
        title: "Sales B2B",
        type: "item",
        url: "/sales/salesPos",
        icon: icons.IconReportMoney,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
      {
        id: "sales-posb2c",
        title: "Sales B2C",
        type: "item",
        url: "/sales/salesPosb2c",
        icon: icons.IconReportMoney,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
      {
        id: "salesReturn",
        title: "Sales Return",
        type: "item",
        url: "/sales/salesReturn",
        icon: icons.IconTruckReturn,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
      {
        id: "salesReturnB2C",
        title: "Sales ReturnB2C",
        type: "item",
        url: "/sales/salesReturnB2C",
        icon: icons.IconTruckReturn,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
      {
        id: "orderForm",
        title: "Order form ",
        type: "item",
        url: "/sales/orderForm",
        icon: icons.IconTruckReturn,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
    ],
  },
  {
    id: "purchases",
    title: "Purchases",
    type: "group",
    condition: [
      {
        conditionTitle: "view",
        status: true,
      },
      {
        conditionTitle: "create",
        status: true,
      },
      {
        conditionTitle: "update",
        status: true,
      },
      {
        conditionTitle: "delete",
        status: true,
      },
      {
        conditionTitle: "print",
        status: true,
      },
    ],
    children: [
      {
        id: "purchase",
        title: "Purchase",
        type: "item",
        url: "/purchase/purchasebill",
        icon: icons.IconShoppingCartPlus,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
      {
        id: "purchaseRetrun",
        title: "Purchase Return",
        type: "item",
        url: "/purchase/purchasebillReturn",
        icon: icons.IconTruckReturn,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
      {
        id: "purchaseImport",
        title: "Purchase Import",
        type: "item",
        url: "/purchase/purchaseImport",
        icon: icons.IconTruckReturn,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
      {
        id: "purchaseOrder",
        title: "Purchase Order",
        type: "item",
        url: "/purchase/PurchaseOrder",
        icon: icons.IconTruckReturn,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
    ],
  },
  {
    id: "reports",
    title: "Reports",
    type: "group",
    condition: [
      {
        conditionTitle: "view",
        status: true,
      },
      {
        conditionTitle: "create",
        status: true,
      },
      {
        conditionTitle: "update",
        status: true,
      },
      {
        conditionTitle: "delete",
        status: true,
      },
      {
        conditionTitle: "print",
        status: true,
      },
    ],
    children: [
      // {
      //     id: 'GST-Reports',
      //     title: 'GST Reports',
      //     type: 'item',
      //     url: '/reports/gst',
      //     icon: icons.IconCoin,
      //     breadcrumbs: false
      // },
      {
        id: "VAT-Reports",
        title: "VAT Reports",
        type: "item",
        url: "/reports/vat",
        icon: icons.IconCashBanknote,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
      {
        id: "Sales-Report",
        title: "Sales Report",
        type: "item",
        url: "/reports/SalesReport",
        icon: icons.IconReportMoney,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
      {
        id: "SalesReturn-Report",
        title: "SalesReturn Report",
        type: "item",
        url: "/reports/SalesReturnReport",
        icon: icons.IconFileAnalytics,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
      {
        id: "Purchase-Report",
        title: "Purchase Report",
        type: "item",
        url: "/reports/PurchaseReport",
        icon: icons.IconFileAnalytics,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
      {
        id: "Stock-Report",
        title: "Stock Report",
        type: "item",
        url: "/reports/Stock",
        icon: icons.IconTruckReturn,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
      {
        id: "Batch-WiseStock-Report",
        title: "Batch Wise Stock Report",
        type: "item",
        url: "/reports/BatchWiseStock",
        icon: icons.IconTruckReturn,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
      {
        id: "Bill-Wise-Profit-Report",
        title: "Bill Wise Profit Report",
        type: "item",
        url: "/reports/BillWiseProfitReport",
        icon: icons.IconTruckReturn,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
    ],
  },
  {
    id: "setup",
    title: "Setup",
    type: "group",
    condition: [
      {
        conditionTitle: "view",
        status: true,
      },
      {
        conditionTitle: "create",
        status: true,
      },
      {
        conditionTitle: "update",
        status: true,
      },
      {
        conditionTitle: "delete",
        status: true,
      },
      {
        conditionTitle: "print",
        status: true,
      },
    ],
    children: [
      {
        id: "category/subcategory",
        title: "Category/Sub",
        type: "item",
        url: "/others/createcategory",
        icon: icons.IconBrandAsana,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
      {
        id: "userPrivilege",
        title: "User Privilege",
        type: "item",
        url: "/others/userPrivilege",
        icon: icons.IconBrandAsana,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
      {
        id: "taxUtilities",
        title: "Tax Utilities",
        type: "item",
        url: "/others/taxUtilities",
        icon: icons.IconBrandAsana,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
      {
        id: "CurrencyRate",
        title: "Currency Rate",
        type: "item",
        url: "/others/CurrencyRate",
        icon: icons.IconBrandAsana,
        breadcrumbs: false,
        condition: [
          {
            conditionTitle: "view",
            status: true,
          },
          {
            conditionTitle: "create",
            status: true,
          },
          {
            conditionTitle: "update",
            status: true,
          },
          {
            conditionTitle: "delete",
            status: true,
          },
          {
            conditionTitle: "print",
            status: true,
          },
        ],
      },
    ],
  },
];
export default menuItems;
