import { useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import { Menu, Button, MenuItem, Container, Grid } from "@mui/material";

// import { useResponsive } from "src/hooks/use-responsive";

// import { HEADER } from "./config-layout";
import { homeUrl } from "config";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import navConfig from "./navMenuItems";

import menuItem from "../../../../menu-items";
import { store } from "store";
import { useSelector } from "react-redux";

function TopNavbar({ onOpenNav }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const userDetail = useSelector((state) => state.common.userDetail);

  const menuData =
  userDetail?.prev && userDetail.prev.length > 0 ? userDetail.prev : menuItem;

  // const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  // const [activeSubMenu, setActiveSubMenu] = useState(null);
  const state = store.getState();
  const isAccountsShow = state.common.isAccountsShow;

  const handleMainClick = (event, idx) => {
    if (openMenu === idx) {
      // Close menu if it's already open
      setAnchorEl(null);
      setOpenMenu(null);
      // setSubMenuAnchorEl(null);
      // setActiveSubMenu(null);
    } else {
      // Open selected menu
      setAnchorEl(event.currentTarget);
      setOpenMenu(idx);
      // setSubMenuAnchorEl(null);
      // setActiveSubMenu(null);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenu(null);
    // setSubMenuAnchorEl(null);
    // setActiveSubMenu(null);
  };

  const handleSubMenuClick = (event, child, index) => {
    if (child.children) {
      // setSubMenuAnchorEl(event.currentTarget);
      // setActiveSubMenu(index);
    } else if (child.path) {
      // navigate(child.path);
      handleMenuClose();
    }
  };

  const toHome = () => {
    window.location.href = homeUrl;
  };

  return (
    <Toolbar
      sx={{
        height: 1,
        px: { lg: 5 },
      }}
    >
      <Grid container spacing={1}>
        <Grid item>
          <Button
            onClick={toHome}
            variant="contained"
            sx={{
              width: "100px",
              borderRadius: "50px",
              background: "#4527a0",
            }}
          >
            Home
          </Button>
        </Grid>
        {menuData
              .filter((value) =>
                value.condition?.some(
                  (cond) =>
                    cond.conditionTitle === "view" && cond.status === true
                )
              ) // Filter items where "view" condition is true
              .map((value, idx) => (
                <Grid
                  item
                  key={idx}
                  sx={{ display: { xs: "none", md: "block" } }}
                >
                  <Box>
                    <Button
                      variant="outlined"
                      startIcon={value.icon}
                      sx={{
                        width: "100px",
                        borderRadius: "50px",
                        borderColor: "#4527a0",
                        color: "#4527a0",
                      }}
                      onClick={
                        (event) =>
                          Array.isArray(value.children) &&
                          value.children.length > 0
                            ? handleMainClick(event, idx) // Open dropdown for parents with children
                            : navigate(value.url) // Navigate for parents without children
                      }
                    >
                      {typeof value.title === "string"
                        ? value.title
                        : "Untitled"}
                    </Button>

                    {/* Dropdown Menu for items with children */}
                    {Array.isArray(value.children) &&
                      value.children.length > 0 && (
                        <Menu
                          open={openMenu === idx}
                          anchorEl={anchorEl}
                          onClose={handleMenuClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          {value.children
                            .filter((child) =>
                              child.condition?.some(
                                (cond) =>
                                  cond.conditionTitle === "view" &&
                                  cond.status === true
                              )
                            ) // Filter children based on "view" condition
                            .map((child, index) => (
                              <MenuItem
                                key={index}
                                onClick={() => navigate(child.url)}
                              >
                                <Link
                                  to={child.url}
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                    width: "100%",
                                  }}
                                  onClick={handleMenuClose}
                                >
                                  {typeof child.title === "string"
                                    ? child.title
                                    : "Untitled"}
                                </Link>
                              </MenuItem>
                            ))}
                        </Menu>
                      )}
                  </Box>
                </Grid>
              ))}
      </Grid>
    </Toolbar>
    // </AppBar>
  );
}

export default TopNavbar;

TopNavbar.propTypes = {
  onOpenNav: PropTypes.func,
};
