import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

// dashboard routing

// utilities routing
const GstReports = Loadable(lazy(() => import("views/reports/GstReports")));
const VatReports = Loadable(lazy(() => import("views/reports/VatReports")));
const BatchWiseStock = Loadable(
  lazy(() => import("views/reports/BatchWiseStock"))
);
const SalesReport = Loadable(lazy(() => import("views/reports/SalesReport")));
const SalesReturnReport = Loadable(
  lazy(() => import("views/reports/SalesReturnReport"))
);
const PurchaseReport = Loadable(
  lazy(() => import("views/reports/PurchaseReport"))
);
const StockReport = Loadable(lazy(() => import("views/reports/StockReport")));
const BillWiseProfitReport = Loadable(
  lazy(() => import("views/reports/BillWiseProfitReport"))
);
// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

const ReportsRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "reports",
      // element: <GstReports />,
      children: [
        {
          path: "gst",
          element: <GstReports />,
        },
        {
          path: "vat",
          element: <VatReports />,
        },
        {
          path: "SalesReport",
          element: <SalesReport />,
        },
        {
          path: "SalesReturnReport",
          element: <SalesReturnReport />,
        },
        {
          path: "PurchaseReport",
          element: <PurchaseReport />,
        },
        {
          path: "Stock",
          element: <StockReport />,
        },
        {
          path: "BatchWiseStock",
          element: <BatchWiseStock />,
        },
        {
          path: "BillWiseProfitReport",
          element: <BillWiseProfitReport />,
        },
      ],
    },
  ],
};

export default ReportsRoutes;
