import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { IconButton } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LoaderDrop from "./LoaderDrop";

function ImgPreview({ open, setOpen, imgLink, loading }) {
  const handleClose = () => {
    setOpen(false);
  };

  
  return (
    <Dialog onClose={handleClose} open={open}>
      <LoaderDrop start={loading} />
      <DialogTitle id="alert-dialog-title">IMAGE PREVIEW</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 15,
          top: 8,
          color: (value) => value.palette.grey[500],
        }}
        size="large"
      >
        <Icon icon="material-symbols:close-rounded" color="black" />
      </IconButton>
      <DialogContent>
        <img
          src={imgLink}
          alt="preview img"
          style={{ boxSizing: "border-box", width: "100%" }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
ImgPreview.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  imgLink: PropTypes.any,
  loading: PropTypes.bool,
};

export default ImgPreview;
