import { useDispatch, useSelector } from "react-redux";

import { Outlet } from "react-router-dom";
import { useEffect } from "react";

import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
// project imports
import Breadcrumbs from "ui-component/extended/Breadcrumbs";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Customization from "../Customization";
import navigation from "menu-items";
import { drawerWidth } from "store/constant";
import { SET_MENU } from "store/actions";
import axios from "axios";
import { baseUrl, redirectUrl } from "config";
// import { setPrinterType, setCompanyInfo } from 'store/actions';
import {
  setPrintrtTyp,
  setCompanyInfo,
  setDecimalCount,
  setPrimaryCurrency,
  setSubCurrency,
  setUserDetail,
  setQrShowPrint,
  setSalesBillDeclaration,
} from "commonSlice/commonSlice";

// assets
import { IconChevronRight } from "@tabler/icons";

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("md")]: {
        marginLeft: -(drawerWidth - 20),
        width: `calc(100% - ${drawerWidth}px)`,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        width: `calc(100% - ${drawerWidth}px)`,
        padding: "16px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
        width: `calc(100% - ${drawerWidth}px)`,
        padding: "16px",
        marginRight: "10px",
      },
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
      },
    }),
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();

  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };
  useEffect(() => {
    function getCompany() {
      axios
        .get(baseUrl + "/main/getCompanyDetails", { withCredentials: true })
        .then((res) => {
          dispatch(setCompanyInfo(res.data[0]));
        });
    }
    function getUser() {
      axios
        .get(baseUrl + "/main/getUserDetails", { withCredentials: true })
        .then((res) => {
          dispatch(setUserDetail(res.data));
        });
    }

    function getPrinter() {
      axios
        .get(baseUrl + "/getPrinterNameAndOther", {
          withCredentials: true,
        })
        .then((res) => {
          // dispatch(setPrintrtTyp(res.data));
          dispatch(setPrintrtTyp(res.data));
          // dispatch(setDecimalCount(res.data[4][0].decimalVal));
          dispatch(setDecimalCount(res.data[1][0].VALUE));
          // dispatch(setPrimaryCurrency(res.data[5][0].PrimaryCurrency));
          dispatch(setPrimaryCurrency(res.data[1][1].VALUE));
          // dispatch(setSubCurrency(res.data[6][0].SubCurrency));
          dispatch(setSubCurrency(res.data[1][2].VALUE));
          dispatch(
            setQrShowPrint(res.data[1][9]?.VALUE === "YES" ? true : false)
          );
          // console.log("data yyyy", res.data[1][11]?.VALUE);
          // dispatch(setSalesBillDeclaration(res.data[1][11]?.VALUE));
        })
        .catch((err) => {
          if (err.response.status === 403) {
            redirectUrl();
          }
        });
    }
    getCompany();
    getPrinter();
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened
            ? theme.transitions.create("width")
            : "none",
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar
        drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
      />

      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened}>
        {/* breadcrumb */}
        <Breadcrumbs
          separator={IconChevronRight}
          navigation={navigation}
          icon
          title
          rightAlign
        />
        <Outlet />
      </Main>
      <Customization />
    </Box>
  );
};

export default MainLayout;
