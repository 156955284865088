import PropTypes from "prop-types";

// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup";
import menuItem from "menu-items";
import { store } from "store";
import { useSelector } from "react-redux";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({ drawerToggle }) => {
  const state = store.getState();
  const isAccountsShow = state.common.isAccountsShow;
  const userDetail = useSelector((state) => state.common.userDetail);

  const menuData =
    userDetail?.prev && userDetail.prev.length > 0 ? userDetail.prev : menuItem;

  // const navItems = menuItem.items.map((item) => {
  // const navItems = menuItem.map((item) => {
  //   if (!isAccountsShow && item.id === "accounts") {
  //     return null; // Return null to exclude it from rendering
  //   }
  //   switch (item.type) {
  //     case "group":
  //       return (
  //         <NavGroup key={item.id} item={item} drawerToggle={drawerToggle} />
  //       );
  //     default:
  //       return (
  //         <Typography key={item.id} variant="h6" color="error" align="center">
  //           Menu Items Error
  //         </Typography>
  //       );
  //   }
  // });
  const navItems = menuData
    .filter((value) =>
      value.condition?.some(
        (cond) => cond.conditionTitle === "view" && cond.status === true
      )
    )
    .map((item) => {
      if (!isAccountsShow && item.id === "accounts") {
        return null; // Return null to exclude it from rendering
      }
      switch (item.type) {
        case "group":
          return (
            <NavGroup key={item.id} item={item} drawerToggle={drawerToggle} />
          );
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });

  return <>{navItems}</>;
};

export default MenuList;

MenuList.propTypes = {
  drawerToggle: PropTypes.any,
};
