import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, IconButton, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import axios from "axios";
import {
  replaceTableData,
  clearTableData,
} from "views/Sales-Sections/SalesRetrunB2C/slice/tableDataSliceReturnB2C";

import { updateSelected } from "../slice/returnHeaderSliceB2C";
import {
  setDiscPer,
  setDiscAmt,
  setTaxAmt,
  setTotal,
  setNetTotal,
  setToDefaultCalc,
  setTaxPer,
  setOther,
} from "../slice/discAndTaxSliceReturnB2C";
import { baseUrl } from "config";
import { redirectUrl } from "config";
import dayjs from "dayjs";
import { useSnackBar } from "context/snackbarContext";
import { setModuleHeading } from "commonSlice/commonSlice";

//--------// APIS_USED_FOR_THIS_COMPONENT //---------//
const customerOutletDealer = "/getMasterAndSalesItemB2C";
const customerOutletDealerB2C = "/getMasterAndSalesItemB2C";
const getReturnNo = "/getMaxBillSrB2C";
const updateBill = "/getSrToUpdate";

function SalesHeader() {
  const moduleName = "SALES RETURN B2C";
  const returnHeaderB2C = useSelector((state) => state.returnHeaderB2C);
  const dispatch = useDispatch();
  const [headerInfo, setHeaderInfo] = useState([]);
  const { showSnackbar } = useSnackBar();
  const taxUtilitiesListData = useSelector(
    (state) => state.common.taxUtilitiesListData
  );
  const decimalCount = useSelector((state) => state.common.decimalCount);

  const onHandleCustomerBalance = (name) => {
    axios({
      method: "post",
      url: baseUrl + "/getPartynameCurrentBalance",
      data: {
        date: dayjs(),
        name: name,
      },
      withCredentials: true,
    })
      .then((res) => {
        dispatch(
          updateSelected({
            balance: Number(res.data[0][0].balance.toFixed(decimalCount)),
            vatNo: res.data[1][0].TinNo,
            address: `${res.data[1][0].BuildingNo},${res.data[1][0].streetName},${res.data[1][0].pin_code},${res.data[1][0].district}`,
          })
        );
      })
      .catch((err) => {
        if (err.response.status === 403 || err.response.status === 401) {
          redirectUrl();
        }
      });
  };

  const invTypMenuItems = [
    { id: 1, label: "B2B" },
    { id: 1, label: "B2C" },
  ];

  const handleChange = (selectedDate) => {
    dispatch(
      updateSelected({
        date: selectedDate,
      })
    );
  };

  const fetchData = async (apiNam) => {
    try {
      const res = await axios.get(baseUrl + apiNam, {
        withCredentials: true,
      });

      setHeaderInfo(res.data);
      // setPartyName(res.data[0][0]);
      dispatch(
        updateSelected({
          customer: res.data[0][0],
          contact: res.data[0][0].Ph,
          dealer: res.data[1][0],
          outlet: res.data[2][0],
          // outlet: res.data[2][0],
          // dealer: res.data[1][0],
        })
      );
    } catch (error) {
      if (
        (error.response && error.response.status === 403) ||
        error.response.status === 401
      ) {
        redirectUrl();
      }
    }
  };

  const getMAsterData = async () => {
    try {
      const res = await axios.get(baseUrl + getReturnNo, {
        withCredentials: true,
      });
      dispatch(
        updateSelected({
          retNo: res.data[0].BillNo,
          retBill: res.data[0].BillNo,
        })
      );
    } catch (error) {
      if (
        (error.response && error.response.status === 403) ||
        error.response.status === 401
      ) {
        redirectUrl();
      }
    }
  };

  useEffect(() => {
    const TaxEnabled = taxUtilitiesListData?.find(
      (data) => data.Typ === "SALES_B2B_AND_B2C"
    );

    if (TaxEnabled) {
      dispatch(
        updateSelected({
          taxEnabled: TaxEnabled.Nam === "YES" ? true : false,
        })
      );
    }
  }, [taxUtilitiesListData]);

  useEffect(() => {
    fetchData(customerOutletDealer);
    getMAsterData();
    dispatch(
      updateSelected({
        invtyp: invTypMenuItems[0],
      })
    );

    //setting module heading in topbar
    dispatch(setModuleHeading(moduleName));
  }, [dispatch]);

  const handleIncrement = () => {
    if (returnHeaderB2C.retNo !== returnHeaderB2C.retBill) {
      dispatch(
        updateSelected({
          retNo: returnHeaderB2C.retNo + 1,
        })
      );
    }
    if (returnHeaderB2C.retNo + 1 < returnHeaderB2C.retBill) {
      dispatch(
        updateSelected({
          edit: true,
        })
      );
    } else {
      dispatch(
        updateSelected({
          edit: false,
        })
      );
    }
    handleEditReturn(returnHeaderB2C.retNo + 1);
  };

  const handleDecrement = () => {
    if (returnHeaderB2C.retNo > 0) {
      dispatch(
        updateSelected({
          retNo: returnHeaderB2C.retNo - 1,
        })
      );
    }
    if (returnHeaderB2C.retNo - 1 < returnHeaderB2C.retBill) {
      dispatch(
        updateSelected({
          edit: true,
        })
      );
    } else {
      dispatch(
        updateSelected({
          edit: false,
        })
      );
    }

    handleEditReturn(returnHeaderB2C.retNo - 1);
  };
  // Customer change
  const handleCustomer = (e, newValue) => {
    // setSelected({ customer: newValue, contact: newValue.Ph });
    onHandleCustomerBalance(newValue.Name);
    dispatch(
      updateSelected({
        customer: newValue,
        contact: newValue.Ph,
      })
    );
  };

  // return no change
  const handleRetNo = (e) => {
    // dispatch(setReturnNo(e.target.value));
    dispatch(
      updateSelected({
        retNo: parseInt(e.target.value),
      })
    );
    if (e.target.value - 1 < returnHeaderB2C.retBill) {
      dispatch(
        updateSelected({
          edit: true,
        })
      );
    } else {
      dispatch(
        updateSelected({
          edit: false,
        })
      );
    }

    handleEditReturn(e.target.value);
  };

  const handleEditReturn = (no) => {
    // Make an axios post request to the updateBill endpoint
    axios
      .post(baseUrl + updateBill, { BillNo: no }, { withCredentials: true })
      .then((res) => {
        if (res.data[1].length > 0) {
          const data = res.data[0][0]; // Simplify data access

          dispatch(replaceTableData(res.data[1]));
          dispatch(updateSelected({ customer: { Name: data.BillPartyName } }));
          dispatch(updateSelected({ date: dayjs(data.BillDate) }));
          dispatch(setDiscPer(data.BillDiscPercField));
          dispatch(setDiscAmt(data.BillDiscAmtField));
          dispatch(setTaxAmt(data.TaxAmt));
          dispatch(setTaxPer(data.BillTaxField));
          dispatch(setTotal(data.BillTotalField));
          dispatch(setNetTotal(data.BillNetTotalField));
          dispatch(updateSelected({ dealer: { Name: data?.Employee } }));
          dispatch(updateSelected({ outlet: { Typ: data?.godown } }));
          dispatch(updateSelected({ invtyp: { label: data?.bill_form } }));
          dispatch(updateSelected({ invo: data?.billno }));
          dispatch(setOther(data.BillPackageField));
        } else {
          dispatch(setToDefaultCalc());
          dispatch(clearTableData());
        }
      })

      .catch((error) => {
        if (
          (error.response && error.response.status === 403) ||
          error.response.status === 401
        ) {
          redirectUrl();
        }
      });
  };

  const handleAutoCom = (e, newValue, nam) => {
    console.log(newValue);

    dispatch(
      updateSelected({
        [nam]: newValue,
      })
    );

    if (nam === "invtyp") {
      if (newValue.label === "B2C") {
        fetchData(customerOutletDealerB2C);
      } else {
        fetchData(customerOutletDealer);
      }
    }
  };

  const handleInvNo = async (e) => {
    dispatch(
      updateSelected({
        invo: e.target.value,
      })
    );
    const getB2B = "/getBillToUpdate8BNEW";
    const getB2C = "/getBillToUpdate8BNEW";

    const selectedUrl =
      returnHeaderB2C.invtyp.label === "B2B" ? getB2B : getB2C;

    setTimeout(async () => {
      try {
        const res = await axios.post(
          `${baseUrl}${selectedUrl}`,
          { BillNo: e.target.value },
          {
            withCredentials: true,
          }
        );

        if (res.data[1].length > 0) {
          const data = res.data[0][0]; // Simplify data access

          dispatch(replaceTableData(res.data[1]));
          dispatch(updateSelected({ customer: { Name: data.BillPartyName } }));
          dispatch(updateSelected({ date: dayjs(data.BillDate) }));
          dispatch(setDiscPer(data.BillDiscPercField));
          dispatch(setDiscAmt(data.BillDiscAmtField));
          dispatch(setTaxAmt(data.TaxAmt));
          dispatch(setTaxPer(data.BillTaxField));
          dispatch(setTotal(data.BillTotalField));
          dispatch(setNetTotal(data.BillNetTotalField));
          dispatch(updateSelected({ dealer: { Name: data.Lorry } }));
          dispatch(updateSelected({ outlet: { Typ: data.godown } }));
          dispatch(setOther(data.BillPackageField));
        } else {
          showSnackbar("error", "no data");
          dispatch(setToDefaultCalc());
          dispatch(clearTableData());
        }
      } catch (error) {
        if (
          (error.response && error.response.status === 403) ||
          error.response.status === 401
        ) {
          redirectUrl();
        }
      }
    }, 1000);
  };

  const priceLevel = [
    { id: 1, label: "A" },
    { id: 2, label: "B" },
    { id: 3, label: "C" },
  ];

  const handleAuto = (name, newValue) => {
    dispatch(
      updateSelected({
        [name]: newValue,
      })
    );
  };
  console.log(
    "returnHeaderB2C.retNo",
    returnHeaderB2C.retNo === returnHeaderB2C.retBill
  );
  console.log("returnHeaderB2C.retNo", returnHeaderB2C.retNo);
  console.log("returnHeaderB2C.retNo", returnHeaderB2C.retBill);

  return (
    <Box sx={{ px: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={5} md={3}>
              <Autocomplete
                disablePortal
                id="outlet-autocomplete"
                options={headerInfo[2] || []}
                getOptionLabel={(option) => option.Typ}
                fullWidth
                onChange={(e, newValue) => handleAutoCom(e, newValue, "Outlet")}
                renderInput={(params) => (
                  // <TextField {...params} label="Outlet" size="small" />
                  <TextField {...params} label="Branch" size="small" />
                )}
                value={returnHeaderB2C.outlet}
              />
            </Grid>
            <Grid item xs={6} sm={5} md={3}>
              {/* <TextField
                type="number"
                label="Return No"
                size="small"
                value={returnHeaderB2C.retNo}
                onChange={() => handleRetNo()}
                inputProps={{
                  min: 0,
                  max: returnHeaderB2C.retBill,
                  style: { fontWeight: "bold", textAlign: "center" },
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              /> */}
              <TextField
                fullWidth
                type="number"
                label="Invoice No"
                size="small"
                value={returnHeaderB2C.retNo}
                onChange={() => handleRetNo()}
                inputProps={{
                  min: 0,
                  max: returnHeaderB2C.retNo,
                  style: {
                    fontWeight: "bold",
                    // textAlign: "start",
                  },
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                InputProps={{
                  startAdornment:
                    returnHeaderB2C.retNo - 1 === 0 ? (
                      ""
                    ) : (
                      <InputAdornment position="start">
                        <IconButton onClick={handleDecrement}>
                          <RemoveCircleIcon color="primary" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  endAdornment:
                    returnHeaderB2C.retNo === returnHeaderB2C.retBill ? (
                      ""
                    ) : (
                      <InputAdornment position="end">
                        <IconButton onClick={handleIncrement}>
                          <AddCircleIcon color="primary" />
                        </IconButton>
                      </InputAdornment>
                    ),
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    paddingLeft: "0.2px", // Adjust for left icon
                    paddingRight: "0.2px", // Adjust for right icon
                  },

                  input: { textAlign: "center" },
                }}
              />
            </Grid>
            <Grid item xs={6} sm={5} md={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  sx={{ width: "100%" }}
                  label=" Date"
                  className="datepick"
                  value={returnHeaderB2C.date}
                  onChange={(selectedDate) => handleChange(selectedDate)}
                  format="DD/MM/YYYY"
                  textField={(props) => (
                    <TextField {...props} fullWidth size="small" />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} sm={5} md={3}>
              <Autocomplete
                disablePortal
                id="dealer-autocomplete"
                options={priceLevel || []}
                fullWidth
                onChange={(e, v) => handleAuto("priceLevel", v)}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Price level" />
                )}
                value={returnHeaderB2C.priceLevel}
              />
            </Grid>
            {/* <Grid item xs={6} sm={5} md={3}>
              <Autocomplete
                disablePortal
                id="outlet-autocomplete"
                options={invTypMenuItems}
                fullWidth
                onChange={(e, newValue) => handleAutoCom(e, newValue, "invtyp")}
                renderInput={(params) => (
                  <TextField {...params} label="InvTyp" size="small" />
                )}
                value={returnHeaderB2C.invtyp}
              />
            </Grid> */}
            <Grid item xs={6} sm={5} md={3}>
              <TextField
                type="number"
                label="Invoice No"
                onChange={(e) => handleInvNo(e)}
                size="small"
                value={returnHeaderB2C.invo || 0}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1} justifyContent={"flex-end"}>
            <Grid item xs={8}>
              <Autocomplete
                disableClearable
                id="combo-box-demo"
                options={headerInfo[0] || []}
                getOptionLabel={(option) => option.Name}
                onChange={(e, newValue) => handleCustomer(e, newValue)}
                renderInput={(params) => (
                  <TextField {...params} label="Party Name" size="small" />
                )}
                value={returnHeaderB2C.customer}
              />
            </Grid>
            <Grid item xs={6} sm={5} md={3}>
              <Autocomplete
                disablePortal
                id="dealer-autocomplete"
                getOptionLabel={(option) => option.Name}
                options={headerInfo[1] || []}
                onChange={(e, newValue) => handleAutoCom(e, newValue, "dealer")}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Dealer" />
                )}
                value={returnHeaderB2C.dealer}
              />
            </Grid>
            <Grid item xs={6} sm={5} md={3}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SalesHeader;
