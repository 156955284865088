import menuItems from "menu-items";

// const getConditionStatus = (userDetail, parentTitle, conditionTitle) => {
//   const menuData =
//     userDetail?.prev && userDetail.prev.length > 0
//       ? userDetail.prev
//       : menuItems;

//   console.log("getConditionStatus(commonState.userDetail,", userDetail.prev.length > 0);

//   const parentItem = menuData.find((item) => item.title === parentTitle);

//   if (!parentItem) {
//     return `Parent title "${parentTitle}" not found`;
//   }

//   const conditionItem = parentItem.condition?.find(
//     (cond) => cond.conditionTitle === conditionTitle
//   );

//   console.log("getConditionStatus(commonState.userDetail,", conditionItem);
//   console.log("getConditionStatus(commonState.userDetail,", conditionItem.status);

//   return conditionItem
//     ? conditionItem.status
//     : `Condition "${conditionTitle}" not found`;
// };
const getConditionStatus = (userDetail, parentTitle, conditionTitle) => {
  const menuData =
    userDetail?.prev && userDetail.prev.length > 0
      ? userDetail.prev
      : menuItems;

  console.log(
    "getConditionStatus(commonState.userDetail,",
    userDetail.prev.length > 0
  );

  // Find the parent item
  let parentItem = menuData.find((item) => item.title === parentTitle);

  // Check if the parent itself contains the condition
  if (parentItem) {
    const conditionItem = parentItem.condition?.find(
      (cond) => cond.conditionTitle === conditionTitle
    );
    if (conditionItem) return conditionItem.status;
  }

  // If not found in parents, search inside children
  for (let item of menuData) {
    if (item.children) {
      const childItem = item.children.find(
        (child) => child.title === parentTitle
      );
      if (childItem) {
        const conditionItem = childItem.condition?.find(
          (cond) => cond.conditionTitle === conditionTitle
        );

          console.log("getConditionStatus(commonState.userDetail,", conditionItem);
          console.log("getConditionStatus(commonState.userDetail,", conditionItem.status);
        return conditionItem
          ? conditionItem.status
          : `Condition "${conditionTitle}" not found`;
      }
    }
  }

  // If not found anywhere
  return `Parent title "${parentTitle}" not found`;
};

export default getConditionStatus;
