import { useRef, useEffect } from "react";
import { Container, Typography, Stack, Divider, Box } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import numberToWords from "number-to-words";
import { useNavigate, useLocation } from "react-router";
// import axios from "axios";
// import { baseUrl } from "config";
import dayjs from "dayjs";
import QrCodeGenerator from "views/commonComponents/QrCode/QrCodeGenerator";
import { useSelector } from "react-redux";

const A4Print = () => {
  const componentRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const { data, tableData, qrData, typ } = location.state;
  const company = useSelector((state) => state.common.companyInfo);
  const companyLogo = company.logo ? JSON.parse(company.logo)?.img : null;
  const decimalCount = useSelector((state) => state.common.decimalCount);
  const primaryCurrency = useSelector((state) => state.common.primaryCurrency);
  const subCurrency = useSelector((state) => state.common.subCurrency);
  const isQrShow = useSelector((state) => state.common.QrShowPrint);
  const commonState = useSelector((state) => state.common);

  console.log("data yyyy", commonState);

  // useEffect(() => {
  //   axios
  //     .get(baseUrl + "/main/getCompanyDetails", { withCredentials: true })
  //     .then((res) => {
  //       setCompany(res.data[0]);
  //     });
  // }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      navigate(-2);
    },
  });

  useEffect(() => {
    if (company) {
      // this timeout is used to show qr code rendering in print modal
      const timeoutId = setTimeout(() => {
        handlePrint();
      }, 100);
      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  function convertNumberToWordsWithCurrency(number) {
    const paisaAmount = (number * 100) % 100;
    const words = numberToWords.toWords(Math.floor(number));
    const paisaInWords = numberToWords.toWords(paisaAmount);
    return `${words} ${primaryCurrency} and  ${paisaInWords} ${subCurrency}`;
  }

  const amount = data.BillNetTotalField;
  const result = convertNumberToWordsWithCurrency(amount);

  console.log("ooooooooooooooooo", primaryCurrency);
  console.log("ooooooooooooooooo", subCurrency);
  console.log("ooooooooooooooooo", result);

  // const generateSections = (tableData) => {
  //   const sections = [];
  //   const itemsPerPage = 16;

  //   sections.push(renderSection(tableData.slice(0, itemsPerPage), 0));

  //   for (let i = itemsPerPage; i < tableData.length; i += itemsPerPage) {
  //     const sectionItems = tableData.slice(i, i + itemsPerPage);
  //     sections.push(renderSection(sectionItems, i));
  //   }

  //   return sections;
  // };
  // const generateSections = (tableData) => {
  //   const sections = [];
  //   const itemsPerPage = 16;

  //   // Assign unique IDs to each product
  //   const updatedTableData = tableData.map((item, index) => ({
  //     ...item,
  //     id: index + 1, // ID starts from 1 and increments
  //   }));

  //   // First section (IDs 1-16)
  //   sections.push(renderSection(updatedTableData.slice(0, itemsPerPage), 0));

  //   // Remaining sections (start from ID 17 and continue)
  //   for (let i = itemsPerPage; i < updatedTableData.length; i += itemsPerPage) {
  //     const sectionItems = updatedTableData.slice(i, i + itemsPerPage);
  //     sections.push(renderSection(sectionItems, i));
  //   }

  //   return sections;
  // };

  const calculateItemsPerPage = (
    tableData,
    maxTableHeight,
    rowHeight,
    lineHeight
  ) => {
    let totalHeight = 0;
    let itemsPerPage = 0;

    for (const item of tableData) {
      // Estimate number of lines in the description
      // const lineCount = Math.ceil((item.ItemNameTextField.length || 0) / 30); // Assume ~30 chars per line
      const lineCount = Math.ceil((item.ItemNameTextField.length || 0) / 30); // Assume ~30 chars per line
      const itemHeight = rowHeight + (lineCount - 1) * lineHeight; // Adjust row height

      console.log("eeeeeeee totalHeight", totalHeight);
      console.log("eeeeeeee itemsPerPage", itemsPerPage);
      console.log("eeeeeeee lineCount", lineCount);
      console.log("eeeeeeee itemHeight", itemHeight);
      console.log(
        "eeeeeeee totalHeight + itemHeight > maxTableHeight",
        totalHeight + itemHeight > maxTableHeight
      );

      if (totalHeight + itemHeight > maxTableHeight) {
        break;
      }

      totalHeight += itemHeight;
      itemsPerPage++;
    }

    return itemsPerPage;
  };

  // const generateSections = (tableData) => {
  //   const sections = [];
  //   const maxTableHeight = 31.1 * 16; // Convert rem to pixels (~500px)
  //   const rowHeight = 30; // Base height for single-line descriptions
  //   const lineHeight = 20; // Height added for each extra line

  //   // Dynamically calculate items per page
  //   const itemsPerPage = calculateItemsPerPage(
  //     tableData,
  //     maxTableHeight,
  //     rowHeight,
  //     lineHeight
  //   );

  //   // Assign unique IDs to each product
  //   const updatedTableData = tableData.map((item, index) => ({
  //     ...item,
  //     id: index + 1, // ID starts from 1 and increments
  //   }));

  //   // First section
  //   sections.push(renderSection(updatedTableData.slice(0, itemsPerPage), 0));

  //   // Remaining sections
  //   for (let i = itemsPerPage; i < updatedTableData.length; i += itemsPerPage) {
  //     const sectionItems = updatedTableData.slice(i, i + itemsPerPage);
  //     sections.push(renderSection(sectionItems, i));
  //   }

  //   console.log("eeeeeeee sections", sections);

  //   return sections;
  // };

  console.log("eeeeeeee sections", isQrShow);

  const generateSections = (tableData) => {
    const sections = [];
    const maxTableHeight = 31.1 * 16; // Convert rem to pixels (~500px)
    const rowHeight = 30; // Base height for single-line descriptions
    const lineHeight = 20; // Height added for each extra line

    // Dynamically calculate items per page
    const itemsPerPage = calculateItemsPerPage(
      tableData,
      maxTableHeight,
      rowHeight,
      lineHeight
    );

    // Assign unique IDs to each product
    const updatedTableData = tableData.map((item, index) => ({
      ...item,
      id: index + 1, // ID starts from 1 and increments
    }));

    // Calculate total pages
    const totalPages = Math.ceil(updatedTableData.length / itemsPerPage);

    // First section
    sections.push(
      renderSection(updatedTableData.slice(0, itemsPerPage), 1, totalPages)
    );

    // Remaining sections
    for (
      let i = itemsPerPage, page = 2;
      i < updatedTableData.length;
      i += itemsPerPage, page++
    ) {
      const sectionItems = updatedTableData.slice(i, i + itemsPerPage);
      sections.push(renderSection(sectionItems, page, totalPages));
    }

    console.log("eeeeeeee sections", sections);
    return sections;
  };

  const renderSection = (sectionItems, sectionIndex, totalPages) => {
    console.log("sectionItems", sectionItems);

    return (
      <div
        key={`section-${sectionIndex}`}
        style={{
          marginTop: sectionIndex !== 0 ? "1rem" : "0",
          pageBreakBefore: "always",
          color: "black",
          borderColor: "black",
          // width: window.innerWidth < 480 ? "100%" : "80%",
          width: "100%",
          padding: "2px",
        }}
      >
        <div style={{ border: "1px solid black" }}>
          <Stack direction="row" sx={{ height: "15.6rem" }}>
            <div
              style={{
                width: "45%",
                borderRight: "1px solid black",
                height: "15.6rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                overflow: "hidden",
              }}
            >
              {/* <div style={{ width: "9.3rem", height: "30%", margin: "1rem" }}> */}
              <div
                style={{ width: "13.3rem", height: "30%", margin: "1rem" }}
                onClick={() => {
                  navigate("/sales/orderForm");
                }}
              >
                <img
                  // src={company.logo}
                  src={companyLogo}
                  style={{
                    objectFit: "contain",
                    height: "100%",
                    width: "100%",
                  }}
                  alt="s"
                />
              </div>

              <Container
                disableGutters
                sx={{ padding: "0.3rem", color: "black" }}
              >
                <Typography variant="h4">{company.Name}</Typography>
                <Typography>{company.Add1}</Typography>
                <Typography>{(company.city_name, company.Place)}</Typography>
                <Typography> {company.Country}</Typography>
                <Typography> Zipcode{company.Postal}</Typography>
                <Typography>Ph : {company.Ph}</Typography>
                <Typography>VAT: {company.Add2}</Typography>
                <Typography> {company.Web}</Typography>
                <Typography> {company.EMail}</Typography>
              </Container>
            </div>
            <Stack direction="column" sx={{ width: "55%", height: "15.6rem" }}>
              <Typography align="center" variant="h4" sx={{ height: "1.5rem" }}>
                {typ === "SB"
                  ? "Tax Invoice"
                  : typ === "OF"
                  ? "Order Form"
                  : "Sales Return"}
              </Typography>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  borderColor: "black",
                }}
              >
                <tbody style={{ padding: "10px", borderColor: "black" }}>
                  <tr>
                    <td
                      style={{
                        // borderRight: "1px solid black",
                        // borderTop: "1px solid black",
                        border: "1px solid black",
                        height: "1.5rem",
                      }}
                    >
                      <Typography>
                        {data.BillNo
                          ? typ === "OF"
                            ? `Voucher No: ${data.NewBillNo}`
                            : typ === "B2C"
                            ? `Invoice No: ${data.prefix}`
                            : `Invoice No: ${data.NewBillNo}`
                          : `Return No: ${data.RetNo}`}
                      </Typography>
                    </td>
                    <td
                      style={{
                        height: "1.5rem",
                        padding: 0,
                        border: "1px solid black",
                      }}
                    >
                      <div style={{ display: "flex", height: "100%" }}>
                        <Typography
                          sx={{
                            width: "80%",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                            p: 0.2,
                          }}
                        >
                          Original for Recipient
                        </Typography>
                        <div
                          style={{
                            width: "20%",
                            height: "100%",
                            borderLeft: "1px solid black",
                          }}
                        ></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        // borderRight: "1px solid black",
                        border: "1px solid black",
                        height: "1.5rem",
                      }}
                    >
                      <Typography>
                        {typ === "OF" ? `Voucher Date:` : ` Invoice Date:`}
                        <b>{dayjs(data.BillDate).format("DD-MM-YYYY")}</b>
                      </Typography>
                    </td>
                    <td
                      style={{
                        height: "1.5rem",
                        padding: 0,
                        border: "1px solid black",
                      }}
                    >
                      <div style={{ display: "flex", height: "100%" }}>
                        <Typography
                          sx={{
                            width: "80%",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                            p: 0.2,
                          }}
                        >
                          Duplicate for Transporter
                        </Typography>
                        <div
                          style={{
                            width: "20%",
                            height: "100%",
                            borderLeft: "1px solid black",
                          }}
                        ></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        // borderRight: "1px solid black",
                        border: "1px solid black",
                      }}
                    >
                      <Typography>
                        State Code: <b>0</b>
                      </Typography>
                    </td>
                    <td
                      style={{
                        height: "1.5rem",
                        padding: 0,
                        border: "1px solid black",
                      }}
                    >
                      <div style={{ display: "flex", height: "100%" }}>
                        <Typography
                          sx={{
                            width: "80%",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "12px",
                            p: 0.2,
                          }}
                        >
                          Triplicate for Supplier
                        </Typography>
                        <div
                          style={{
                            width: "20%",
                            height: "100%",
                            borderLeft: "1px solid black",
                          }}
                        ></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        // borderRight: "1px solid black",
                        borderBottom: "1px solid black",
                        border: "1px solid black",
                      }}
                    >
                      <Typography>
                        Client ID: <b>0</b>
                      </Typography>
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid black",
                        border: "1px solid black",
                      }}
                    >
                      <Typography>
                        Vehicle No: <b>0</b>
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={0}
                sx={{ height: "100%", p: 1 }}
              >
                <Stack direction="column" alignItems={"start"} width={"80%"}>
                  <Typography>
                    <b style={{ display: "block" }}>To :{data.BillPartyName}</b>{" "}
                    {data.CashP}
                  </Typography>
                  <Typography>{data.warranty} </Typography>
                  <Typography>PH: 1234567234 </Typography>
                  <Typography>
                    <b>VAT NO:</b> {data.TinNo}
                  </Typography>
                </Stack>

                <Stack direction="row" justifyContent="center" width={"20%"}>
                  <Box width={120}>
                    {isQrShow ? <QrCodeGenerator qrData={qrData} /> : null}
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          {/* <div style={{ height: "31.1rem" }}> */}
          <div style={{ height: "31.8rem" }}>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                      border: "1px solid black",
                      padding: "0.6rem",
                    }}
                  >
                    SI NO
                  </th>
                  <th
                    style={{
                      textAlign: "left",
                      border: "1px solid black",
                      width: "30%",
                      padding: "0.6rem",
                    }}
                  >
                    Description
                  </th>
                  {/* <th style={{ textAlign: 'center', border: '1px solid black', padding: '0.5rem 0rem' }}>
                                        HSN/SAC Code
                                    </th> */}
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      padding: "0.6rem",
                    }}
                  >
                    Qty
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      padding: "0.6rem",
                    }}
                  >
                    Rate
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      padding: "0.6rem",
                    }}
                  >
                    Unit
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      padding: "0.6rem",
                    }}
                  >
                    Amount
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      padding: "0.6rem",
                    }}
                  >
                    Tax %
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      padding: "0.6rem",
                    }}
                  >
                    Tax amt
                  </th>

                  <th
                    style={{
                      textAlign: "right",
                      border: "1px solid black",
                      padding: "0.6rem",
                    }}
                  >
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {sectionItems.map((prod, index) => (
                  <tr
                    // style={{
                    //   backgroundColor: index % 2 === 0 ? "yellow" : "red",
                    // }}
                    key={index}
                  >
                    <td
                      style={{
                        textAlign: "center",
                        alignContent: "start",
                        // padding: "0.98rem",
                        borderRight: "1px solid black",
                      }}
                    >
                      {prod.id}
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                        borderRight: "1px solid black",
                        paddingLeft: "5px",
                      }}
                    >
                      {prod.ItemNameTextField}
                    </td>
                    {/* <td style={{ textAlign: 'center', borderRight: '1px solid black' }}>{prod.ItemNo}</td> */}
                    <td
                      style={{
                        textAlign: "center",
                        borderRight: "1px solid black",
                        alignContent: "start",
                      }}
                    >
                      {prod.qty}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        borderRight: "1px solid black",
                        alignContent: "start",
                      }}
                    >
                      {parseFloat(prod.Rate1).toFixed(decimalCount)}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        borderRight: "1px solid black",
                        alignContent: "start",
                      }}
                    >
                      {prod.Typ}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        borderRight: "1px solid black",
                        alignContent: "start",
                      }}
                    >
                      {prod.Rate1 * prod.qty.toFixed(decimalCount)}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        borderRight: "1px solid black",
                        alignContent: "start",
                      }}
                    >
                      {prod.Tax1}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        borderRight: "1px solid black",
                        alignContent: "start",
                      }}
                    >
                      {parseFloat(prod.taxAmt).toFixed(decimalCount)}
                    </td>

                    <td
                      style={{
                        textAlign: "right",
                        borderRight: "1px solid black",
                        padding: "5px",
                        alignContent: "start",
                      }}
                    >
                      {prod.total}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Divider sx={{ borderBottom: "0.5px solid black" }} />
          </div>
          {/* <hr style={{ borderColor: 'black' }} /> */}
          <Stack direction="row">
            <Stack
              direction="column"
              sx={{
                width: "60%",
                borderRight: "1px solid black",
              }}
            >
              <Container
                disableGutters
                sx={{ padding: "0.3rem", borderTop: "1px solid black" }}
              >
                <Typography> Amount In Words :</Typography>
                <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
                  {result}
                </Typography>
              </Container>
              <Container
                disableGutters
                sx={{ borderTop: "1px solid black", padding: "0.3rem" }}
              >
                <Typography> Bank Details:-</Typography>
                <Typography variant="h5"> Bank Name: {company.bank}</Typography>
                <Typography variant="h5">
                  {" "}
                  A/c Name: {company.beneficiary}
                </Typography>
                <Typography variant="h5">
                  {" "}
                  A/c No: {company.account_no}
                </Typography>
                <Typography variant="h5"> Branch: {company.branch}</Typography>
                <Typography variant="h5"> IFSC: {company.ifsc_code}</Typography>
                {/* <Typography sx={{ marginTop: 3 }}> UPI: 9544606340</Typography> */}
              </Container>
              <Container
                disableGutters
                sx={{
                  borderTop: "1px solid black",
                  padding: "0.8rem 0.3rem",
                  overflow: "hidden", // Hides any overflowing content
                  textOverflow: "ellipsis", // Adds '...' if text overflows
                  whiteSpace: "nowrap", // Keeps content in a single line (optional)
                  height: "100%",
                }}
              >
                <Typography>Declaration</Typography>
                <div
                  style={{ textOverflow: "ellipsis" }}
                  dangerouslySetInnerHTML={{
                    __html: commonState.salesBillDeclaration,
                  }}
                />
              </Container>
            </Stack>
            <Stack direction="column" sx={{ width: "40%" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  borderTop: "1px solid black",
                  height: "2rem",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                <Typography variant="h5">Subtotal :</Typography>
                <Typography variant="h5">
                  {" "}
                  {data.BillTotalField.toFixed(decimalCount)}
                </Typography>
              </Stack>
              {/* <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ borderTop: '1px solid black', height: '2rem', paddingLeft: '1rem' }}
                            >
                                <Typography variant="h5"> Add CGST : </Typography>
                                <Typography variant="h5"> 0004017</Typography>
                            </Stack>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ borderTop: '1px solid black', height: '2rem', paddingLeft: '1rem' }}
                            >
                                <Typography variant="h5"> Add SGST : </Typography>
                                <Typography variant="h5"> 004017</Typography>
                            </Stack>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ borderTop: '1px solid black', height: '2rem', paddingLeft: '1rem' }}
                            >
                                <Typography variant="h5"> Tax Amt GST : </Typography>
                                <Typography variant="h5"> FC0004017</Typography>
                            </Stack> */}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  borderTop: "1px solid black",
                  height: "2rem",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                <Typography variant="h5"> Tax Amount: </Typography>
                <Typography variant="h5">
                  {" "}
                  {typ !== "SR"
                    ? parseFloat(data?.TItTaxAmt + data?.BillTaxField).toFixed(
                        decimalCount
                      )
                    : parseFloat(data.BillTaxField).toFixed(decimalCount)}
                </Typography>
              </Stack>
              {/* <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ borderTop: '1px solid black', height: '2rem', paddingLeft: '1rem' }}
                            >
                                <Typography variant="h5"> VAT % : </Typography>
                                <Typography variant="h5"> FC0004017</Typography>
                            </Stack> */}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  borderTop: "1px solid black",
                  height: "2rem",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                <Typography variant="h5"> Discount : </Typography>
                {/* <Typography variant="h5"> {data.BillPackageField}</Typography> */}
                <Typography variant="h5">
                  {" "}
                  {data.TotDiscPercAmt + data.BillDiscAmtField}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  borderBlock: "1px solid black",
                  height: "2rem",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                <Typography variant="h5"> Net Total : </Typography>
                <Typography variant="h5">
                  {" "}
                  {parseFloat(data.BillNetTotalField).toFixed(decimalCount)}
                </Typography>
              </Stack>
              <Stack
                direction="column"
                justifyContent="space-between"
                sx={{ height: "5rem", paddingLeft: "5px", paddingRight: "5px" }}
              >
                <Typography
                  align="center"
                  variant="h6"
                  sx={{ marginLeft: "auto" }}
                >
                  E&OE
                </Typography>
                <Typography align="center" variant="h6">
                  For {company.Name}
                </Typography>
                <Typography align="center" variant="h6">
                  Authorised Signatory
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </div>
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            marginTop: "2px",
          }}
        >
          Page {sectionIndex} of {totalPages}
        </div>
      </div>
    );
  };

  const sections = generateSections(tableData);

  console.log("eeeeeeee sections", sections);

  return (
    <div>
      {/* {isQrCodeGenerated && ( */}
      {/* <a href="#" onClick={handlePrint}>
        Print this out!
      </a> */}
      {/* )} */}
      <div
        ref={componentRef}
        style={{
          background: "white",
          // border: "1px solid black",
          margin: "1rem",
          height: "auto",
        }}
      >
        {sections}
        {/* <QrCodeGenerator qrData={qrData} onGenerated={handleQrCodeGenerated} /> */}
      </div>
    </div>
  );
};

export default A4Print;
