import { createSlice } from "@reduxjs/toolkit";

const tableDataPosSlice = createSlice({
  name: "tableDataPos",
  initialState: [],
  reducers: {
    addItemToTableData: (state, action) => {
      console.log("actionnnnnnnnnnnnnnnn", action);

      state.push(action.payload);
    },

    updateQtyAndTotal: (state, action) => {
      const { index, qty, total, taxAmt } = action.payload;
      // state[index].qty = parseInt(qty);
      state[index].qty = qty;
      state[index].total = total;
      state[index].taxAmt = taxAmt;
    },

    updateBatch: (state, action) => {
      const { index, batch, edite } = action.payload;
      if (edite) {
        state[index].batch = batch.split(", ");
      } else {
        state[index].batch = [...state[index].batch, batch];
      }
    },

    selectedRowOrCurrentRow: (state, action) => {
      // Iterate over all items in the state.
      const { name } = action.payload;

      state.forEach((item) => {
        // Set selectedRow to true for the current index, false for others
        item.selectedRow = item.ItemNameTextField === name;
      });
    },

    updateRateAndTotal: (state, action) => {
      const { index, Rate1, qty, taxAmt } = action.payload;
      state[index].Rate1 = Rate1;
      state[index].total = Rate1 * qty + taxAmt;
      state[index].taxAmt = taxAmt;
    },

    updateRateAndTotalTaxUtilityEnabled: (state, action) => {
      const { index, Rate1, qty, taxAmt } = action.payload;
      state[index].Rate1 = Rate1;
      state[index].total = Rate1 * qty;
      state[index].taxAmt = taxAmt;
    },
    deleteItemFromTableData: (state, action) => {
      const index = state.findIndex(
        (item) => item.ItemNo === action.payload.ItemNo
      );
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    clearTableData: (state, action) => {
      return [];
    },
    // New reducer to replace the array with new data
    replaceTableData: (state, action) => {
      return action.payload; // Set the state to the new data
    },
  },
});

export const {
  addItemToTableData,
  updateRateAndTotal,
  updateRateAndTotalTaxUtilityEnabled,
  updateQtyAndTotal,
  deleteItemFromTableData,
  clearTableData,
  replaceTableData,
  updateBatch,
  selectedRowOrCurrentRow,
} = tableDataPosSlice.actions;
export default tableDataPosSlice.reducer;
